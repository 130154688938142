// GENERAL

// Default Drawer data-test-ids
export const defaultDrawerIds: { [key: string]: string } = {
  drawerProjectsIcon: 'drawer-project-icon',
  drawerOrganizationIcon: 'drawer-organization-icon',
  drawerVendorsIcon: 'drawer-vendors-icon',
  drawerSupportIcon: 'drawer-support-icon',
  drawerOpenIcon: 'drawer-open-icon',
  drawerCloseIcon: 'drawer-close-icon',
};

// Header data-test-ids
export const headerIds: { [key: string]: string } = {
  notificationsIcon: 'notifications-icon',
  notificationsUnreadIcon: 'notifications-unread-icon',
};

// Project Creation data-test-ids
export const projectCreationIds: { [key: string]: string } = {
  projectCreationAddUserButton: 'project-creation-add-user-button',
  projectCreationResetFrameworksButton: 'project-creation-reset-Frameworks-button',
};

// Mixed data-test-ids
export const mixedIds: { [key: string]: string } = {
  arrowBackButton: 'arrow-back-button',
  editTitleButton: 'edit-title-button',
  editDescriptionButton: 'edit-description-button',
};

// Organization

export const organizationIds: { [key: string]: string } = {
  organizationTitleText: 'organization-title-text',
  organizationUserManagementTab: 'organization-user-management-tab',
};

// Users Management

export const organizationUserManagementIds: { [key: string]: string } = {
  usersManagementAddUserButton: 'users-management-add-user-button',
  usersManagementUsersTab: 'users-management-users-tab',
  usersManagementRolesTab: 'users-management-roles-tab',
  usersManagementUsersTable: 'users-management-users-table',
  usersManagementRolesTable: 'users-management-roles-table',
};

// CONTROL

// Control data-test-ids
export const controlIds: { [key: string]: string } = {
  controlCodeText: 'control-code-text',
  controlReportAiBadge: 'control-report-ai-badge',
  controlReportEditor: 'control-report-editor',
  controlReportAiEditor: 'control-report-ai-editor',
  controlReportAiEditorEditIcon: 'control-report-ai-editor-edit-icon',
  controlReportAiEditorDeleteIcon: 'control-report-ai-editor-delete-icon',
  controlReportAiEditorRefreshIcon: 'control-report-ai-editor-refresh-icon',
};

// Control Evidence Component data-test-ids
export const controlEvidenceComponentIds: { [key: string]: string } = {
  controlEvidenceComponentPreviewEvidenceIcon: 'control-evidence-component-preview-evidence-icon',
  controlEvidenceComponentDeleteEvidenceIcon: 'control-evidence-component-delete-evidence-icon',
};

// VENDORS

export const vendorsOverviewIds: { [key: string]: string } = {
  vendorsOverviewTitleText: 'vendors-overview-title-text',
  vendorsOverviewNewVendorButton: 'vendors-overview-new-vendor-button',
  vendorsOverviewTypeSelect: 'vendors-overview-type-select',
  vendorsOverviewStatusSelect: 'vendors-overview-status-select',
  vendorsOverviewRiskLevelSelect: 'vendors-overview-risk-level-select',
};

export const createVendorIds: { [key: string]: string } = {
  createVendorNameInput: 'create-vendor-name-input',
  createVendorTypeSelect: 'create-vendor-type-select',
  createVendorWebsiteInput: 'create-vendor-website-input',
  createVendorDescriptionInput: 'create-vendor-description-input',
  createVendorAdressStreetInput: 'create-vendor-adress-street-input',
  createVendorAdressNumberInput: 'create-vendor-adress-number-input',
  createVendorAdressZipCodeInput: 'create-vendor-adress-zip-code-input',
  createVendorAdressCityInput: 'create-vendor-adress-city-input',
  createVendorAdressStateInput: 'create-vendor-adress-state-input',
  createVendorAdressCountrySelect: 'create-vendor-adress-country-select',
  createVendorResponsiblePersonInput: 'create-vendor-responsible-person-input',
  createVendorStatusSelect: 'create-vendor-status-select',
  createVendorRiskLevelSelect: 'create-vendor-risk-level-select',
  createVendorReviewDateInput: 'create-vendor-review-date-input',
  createVendorAnnualContractInput: 'create-vendor-annual-contract-input',
  createVendorSubprocessorCheckbox: 'create-vendor-subprocessor-checkbox',
  createVendorPrivacyPolicyInput: 'create-vendor-privacy-policy-input',
  createVendorTermsOfServiceInput: 'create-vendor-terms-of-service-input',
  createVendorAddButton: 'create-vendor-add-button',
};

export const editVendorIds: { [key: string]: string } = {
  editVendorNameInput: 'edit-vendor-name-input',
  editVendorTypeSelect: 'edit-vendor-type-select',
  editVendorWebsiteInput: 'edit-vendor-website-input',
  editVendorDescriptionInput: 'edit-vendor-description-input',
  editVendorAdressStreetInput: 'edit-vendor-adress-street-input',
  editVendorAdressNumberInput: 'edit-vendor-adress-number-input',
  editVendorAdressZipCodeInput: 'edit-vendor-adress-zip-code-input',
  editVendorAdressCityInput: 'edit-vendor-adress-city-input',
  editVendorAdressStateInput: 'edit-vendor-adress-state-input',
  editVendorAdressCountrySelect: 'edit-vendor-adress-country-select',
  editVendorResponsiblePersonSelect: 'edit-vendor-responsible-person-input',
  editVendorStatusSelect: 'edit-vendor-status-select',
  editVendorRiskLevelSelect: 'edit-vendor-risk-level-select',
  editVendorReviewDateInput: 'edit-vendor-review-date-input',
  editVendorAnnualContractInput: 'edit-vendor-annual-contract-input',
  editVendorSubprocessorCheckbox: 'edit-vendor-subprocessor-checkbox',
  editVendorPrivacyPolicyInput: 'edit-vendor-privacy-policy-input',
  editVendorTermsOfServiceInput: 'edit-vendor-terms-of-service-input',
  editVendorSaveButton: 'edit-vendor-save-button',
};

export const vendorDetailsIds: { [key: string]: string } = {
  vendorDetailsName: 'vendor-details-name',
  vendorDetailsWebsite: 'vendor-details-website',
  vendorDetailsDescription: 'vendor-details-description',
  vendorDetailsAdress: 'vendor-details-adress',
  vendorDetailsPrivacyPolicy: 'vendor-details-privacy-policy',
  vendorDetailsTermsOfService: 'vendor-details-terms-of-service',
  vendorDetailsResponsiblePerson: 'vendor-details-responsible-person',
  vendorDetailsStatus: 'vendor-details-status',
  vendorDetailsRiskLevel: 'vendor-details-risk-level',
  vendorDetailsReviewDate: 'vendor-details-review-date',
  vendorDetailsAnnualContract: 'vendor-details-annual-contract',
  vendorDetailsSubprocessor: 'vendor-details-subprocessor',
  vendorDetailsType: 'vendor-details-type',
};

// MODALS

export const profileModalIds: { [key: string]: string } = {
  profileModalLogoutIcon: 'profile-modal-logout-icon',
  profileModalSettingsIcon: 'profile-modal-settings-icon',
  profileModalTokenIcon: 'profile-modal-token-icon',
};

export const evidencePreviewIds: { [key: string]: string } = {
  evidencePreviewCreatedByValue: 'evidence-preview-created-by-value',
  evidencePreviewTypeValue: 'evidence-preview-type-value',
  evidencePreviewLastUpdateValue: 'evidence-preview-last-update-value',
  evidencePreviewFileSizeValue: 'evidence-preview-file-size-value',
};

// New Evidence Modal data-test-ids
export const newEvidenceIds: { [key: string]: string } = {
  newEvidenceNameValue: 'new-evidence-name-value',
  newEvidenceDescriptionValue: 'new-evidence-description-value',
  newEvidenceAiBadge: 'new-evidence-ai-badge',
  newEvidenceAiEditor: 'new-evidence-ai-editor',
  newEvidenceEditNameIcon: 'new-evidence-edit-name',
  newEvidenceDeleteNameIcon: 'new-evidence-delete-name',
  newEvidenceRefreshNameIcon: 'new-evidence-delete-name',
  newEvidenceEditDescriptionIcon: 'new-evidence-edit-description',
  newEvidenceDeleteDescriptionIcon: 'new-evidence-delete-description',
  newEvidenceRefreshDescriptionIcon: 'new-evidence-delete-name',
};

// Edit Evidence Modal data-test-ids
export const editEvidenceIds: { [key: string]: string } = {
  editEvidenceEditNameIcon: 'edit-evidence-edit-name',
  editEvidenceDeleteNameIcon: 'edit-evidence-delete-name',
  editEvidenceRefreshNameIcon: 'edit-evidence-delete-name',
  editEvidenceEditDescriptionIcon: 'edit-evidence-edit-description',
  editEvidenceDeleteDescriptionIcon: 'edit-evidence-delete-description',
  editEvidenceRefreshDescriptionIcon: 'edit-evidence-delete-name',
};

export const aiAgentModalIds: { [key: string]: string } = {
  aiAgentModal: 'ai-agent-modal',
  linkAddressInput: 'ai-agent-modal-link-address-input',
  runAgentButton: 'ai-agent-modal-run-button',
};

export const confirmationModalIds: { [key: string]: string } = {
  confirmationModal: 'confirmation-modal',
  confirmationModalTitle: 'confirmation-modal-title',
  confirmationModalConfirmButton: 'confirmation-modal-confirm-button',
  confirmationModalCancelButton: 'confirmation-modal-cancel-button',
};

export const tokenModalIds: { [key: string]: string } = {
  tokenModalCopyIcon: 'token-modal-copy-icon',
};

export const invitedUserModalIds: { [key: string]: string } = {
  resendInvitationButton: 'resend-invitation-button',
  cancelInvitationButton: 'cancel-invitation-button',
};

export const editUserModalIds: { [key: string]: string } = {
  editUserModal: 'edit-user-modal',
  editUserModalActivateButton: 'edit-user-modal-activate-button',
  editUserModalDeactivateButton: 'edit-user-modal-deactivate-button',
};

// TABLE
export const tableIds: { [key: string]: string } = {
  tableComponent: 'table-component',
  tableSearchInput: 'table-search-input',
};

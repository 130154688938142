import App from '@/App.vue';
import { createApp } from 'vue';
import router from './router';
import { createGtm } from '@gtm-support/vue-gtm';

import globalAxios from 'axios';
import { createPinia } from 'pinia';

import defineInterceptors from '@/api/httpClient.ts';
import * as Sentry from '@sentry/vue';

// Import icon libraries
import '@quasar/extras/material-icons/material-icons.css';
// Import Quasar css
import 'quasar/src/css/index.sass';
import { Loading, Notify, Quasar } from 'quasar';

import 'highlight.js/styles/default.css';

import veProgress from 'vue-ellipse-progress';
import '/node_modules/flag-icons/css/flag-icons.min.css';

const pinia = createPinia();
const app = createApp(App);

Notify.setDefaults({
  color: 'green',
  textColor: 'white',
  timeout: 4000,
  actions: [
    {
      icon: 'close',
      color: 'white',
    },
  ],
});

app.use(pinia);
app.use(router);
app.use(Quasar, {
  plugins: { Notify, Loading }, // import Quasar plugins and add here
  config: {
    notify: {
      /* look at QuasarConfOptions from the API card */
    },
    loading: {},
    dark: false,
  },
});
app.use(veProgress);
app.use(
  createGtm({
    id: 'GTM-WB3J693G',
    vueRouter: router,
    enabled: import.meta.env.MODE === 'prod' || import.meta.env.MODE === 'production',
  }),
);

// app.use(mavonEditor);

window.addEventListener('vite:preloadError', (event) => {
  event.preventDefault(); // Prevent vite:preloadError from being thrown https://vitejs.dev/guide/build.html#load-error-handling , dynamic imports issue.

  Notify.create({
    message:
      'A new version of the application is now available. Please save any unsaved work before reloading the page to access the latest version.',
    color: 'red',
    timeout: 0,
    position: 'top',
    actions: [
      {
        label: 'Refresh',
        color: 'white',
        handler: () => {
          // Capture message to evaluate periodicity of execution of the issue.
          //TODO fin a better why to track.

          Sentry.captureMessage('Refresh button clicked. vite:preloadError');
          window.location.reload();
        },
      },
      {
        icon: 'close',
        color: 'white',
        handler: () => {
          // Capture message to evaluate periodicity of execution of the issue.
          //TODO fin a better why to track.

          Sentry.captureMessage('Close button clicked. vite:preloadError');
        },
      },
    ],
    ignoreDefaults: true,
  });
});

if (import.meta.env.VITE_SENTRY_ENABLED) {
  Sentry.init({
    app,
    environment: import.meta.env.MODE,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: import.meta.env.VITE_APP_VERSION,
    integrations: [
      Sentry.browserTracingIntegration({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          new RegExp(
            `^${import.meta.env.VITE_API_URL.replace(/:\//g, '://').replace(/\./g, '\\.')}`,
          ),
        ],
        router,
      }),
      Sentry.feedbackIntegration({
        // Additional SDK configuration goes in here, for example:
        showName: false,
        showEmail: false,
        colorScheme: 'light',
        showBranding: false,
        triggerLabel: '',
        buttonLabel: 'Provide Feedback',
        submitButtonLabel: 'Send Your Feedback',
        formTitle: 'Provide Feedback',
        successMessageText: 'Thanks for your feedback!',
        messagePlaceholder:
          'Please share any positive experiences or let us know if you encountered any issues or bugs.',
        onFormOpen: () => {
          const user = localStorage.getItem('user');
          if (user) {
            const parsedUser = JSON.parse(localStorage.getItem('user')!);
            Sentry.setUser({
              id: parsedUser.id,
            });
            Sentry.setContext('organization', {
              id: parsedUser.organization.id,
              name: parsedUser.organization.name,
            });
          }
        },
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

app.mount('#app');

defineInterceptors(globalAxios, router);
